.film__container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.film__box {
    height: 500px;
    width: 100%;
    max-width: 800px;
}

.player-wrapper {
   position: relative;
   
}
  
  .react-player video {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    
}